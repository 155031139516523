import {useEffect} from "react";

const GroupBidDropdown = ({ availableGroups, selectedGroup, setSelectedGroup }) => {
    const handleSelectChange = (event) => {
        let selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        // Check if the first item is selected, if not, add it back
        if (selectedOptions.indexOf(availableGroups[0].bid_name) === -1) {
            selectedOptions = [availableGroups[0].bid_name, ...selectedOptions];
        }
        setSelectedGroup(selectedOptions);
    };

    // Update the selected items based on availableGroups and selectedGroup
    useEffect(() => {
        if (availableGroups && availableGroups.length > 0) {
            // Ensure that the selectedGroup items are in the availableGroups
            const validSelectedGroups = selectedGroup.filter(group =>
                availableGroups.some(avGroup => avGroup.bid_name === group)
            );

            // If selectedGroup is empty or contains invalid items, set it to the default
            if (validSelectedGroups.length === 0) {
                setSelectedGroup([availableGroups[0].bid_name]);
            } else if (validSelectedGroups.length !== selectedGroup.length) {
                setSelectedGroup(validSelectedGroups);
            }
        }
    }, [availableGroups, selectedGroup, setSelectedGroup]);

    return (
        <select multiple={true} value={selectedGroup} onChange={handleSelectChange}>
            {availableGroups.map((group) => (
                <option key={group.id} value={group.bid_name}>
                    {group.bid_name}
                </option>
            ))}
        </select>
    );
};

export default GroupBidDropdown;
