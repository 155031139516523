import React, { useState, useEffect } from 'react';
import axios from 'axios';
import getCsrfToken from '../utils/getCsrfToken';
import { API_BASE_URL } from '../config/config';
import '../styles/NotesBidHistory.css';

function BidDetails({ bidId }) {
    const [notes, setNotes] = useState('');
    const [updatedNotes, setUpdatedNotes] = useState('');

    useEffect(() => {
        // Fetch notes from the backend when bidNum changes
        axios.get(`${API_BASE_URL}/api/notes/${bidId}`, { withCredentials: true } )
            .then(response => {
                setNotes(response.data.note || '');
                setUpdatedNotes(response.data.note || '');
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    }, [bidId]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (updatedNotes !== notes) {
                // Update notes in the backend
                try {
                    axios({
                        method: 'post',
                        url: `${API_BASE_URL}/api/notes/${bidId}`,
                        data: {note: updatedNotes},
                        withCredentials: true,
                        headers: {
                            'X-CSRFToken': getCsrfToken(),
                        }
                    })
                        .then(() => {
                            setNotes(updatedNotes);
                        });
                }
                catch (error) {
                    console.error("Error updating bid:", error);
                }
            }
        }, 1200);

        return () => clearInterval(interval); // Clean up on unmount
    }, [updatedNotes, notes, bidId]);

    const handleNotesChange = (event) => {
        const newNotes = event.target.value;
        setUpdatedNotes(newNotes);
    }


    return (
        <div className="note-details">
            <h3>Personal Notes</h3>
            <textarea className={'note-text'} value={updatedNotes} onChange={handleNotesChange} />
        </div>
    );
}

export default BidDetails;