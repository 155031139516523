import React, { useState, useRef } from 'react';
import { API_BASE_URL} from "../../config/config";
import axios from "axios";
import getCsrfToken from '../../utils/getCsrfToken';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';


function LoginForm( {userInfo , onClose} ) {
    const [isRegisterMode, setIsRegisterMode] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const recaptchaRef = useRef(null);
    let recaptchaToken = '';

    const validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            await axios({
                method: 'post',
                url: `${API_BASE_URL}/user/login/`,
                data: { username, password, recaptchaToken },
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            }).then((response) => {
                const { id, first_name, last_name } = response.data;
                userInfo(id, first_name, last_name, username);
                onClose();
                setErrorMessage('')
                if (recaptchaToken)
                    recaptchaRef.current.reset();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setErrorMessage('Invalid username or password');
                    setPassword('')
                }
                else {
                console.log('Login error: ', error);
                }
            });

        } catch (error) {
            setErrorMessage(error.response.data.message);
            if (error.response.data.message === 'reCAPTCHA required') {
                setErrorMessage('')
                recaptchaToken = await recaptchaRef.current.executeAsync().catch(error => console.log(error));
            }
        }
    };
    const handleKeyDown = (event) => {
        if(event.key === 'Enter'){
            event.preventDefault(); // Prevent form from being submitted twice
            isRegisterMode ? handleRegister(event) : handleLogin(event);
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }
        if (!validateEmail(email)) {
            setErrorMessage('Invalid email address');
            return;
        }
        if (password.length < 8) {
            setErrorMessage('Password must be at least 8 characters long');
            return;
        }
        recaptchaToken = await recaptchaRef.current.executeAsync().catch(error => console.log(error));
        try {
            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/user/register/`,
                data: { username, first_name, last_name, email, password, recaptchaToken },
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            }).catch((error) => {
                console.log('Login error: ', error);
                // Add your logic to handle the error
            });

            if (response.status === 201) {
                window.alert('User Created');
                onClose();
                setErrorMessage('')
                try {
                    recaptchaRef.current.reset();
                } catch (resetError) {
                    console.log('Error resetting reCAPTCHA:', resetError);
                }
            }
        } catch (error) {
            console.log(error)
            setErrorMessage(error.response.data.message);
        }
    };

    return (
        <div className="login-form-container">
            <h2>{isRegisterMode ? 'Register' : 'Login'}</h2>
            <form onSubmit={isRegisterMode ? handleRegister : handleLogin}>
                <div className="login-form-fields">
                    <input
                        type="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Username"
                    />
                    {isRegisterMode && (
                        <>
                            <input
                                type="text"
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="First Name"
                            />
                            <input
                                type="text"
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Last Name"
                            />
                            <input
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Email"
                            />
                        </>
                    )}
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Password"
                    />
                    {isRegisterMode && (
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Confirm Password"
                        />
                    )}
                </div>
                {errorMessage &&
                    <p className="error-message">
                        {errorMessage}
                        {errorMessage === 'Invalid username or password' && <Link to="/forgot-password"> Forgot password?</Link>}
                    </p>
                }
                <div className="form-buttons">
                    <button type="submit">{isRegisterMode ? 'Register' : 'Login'}</button>
                    <button type="button" onClick={() => {
                        setIsRegisterMode(!isRegisterMode);
                        setErrorMessage('');
                        setEmail('');
                        setPassword('');
                    }}>
                        Switch to {isRegisterMode ? 'Login' : 'Register'}
                    </button>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6Lfy-DwpAAAAAHdlL8_NIOnQvttGD1tsCb0NpN8z" // needs to be replaced in production
                />
            </form>
        </div>
    );
}

export default LoginForm;