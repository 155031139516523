import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config/config';
import '../styles/AgreementBid.css';
import getCsrfToken from '../utils/getCsrfToken';

function AddBidPopup({ agreementId, parentBid, onClose, onUpdateBids, toggleState, currentAdd, selectedBidId }) {
    const [availableBids, setAvailableBids] = useState([]);
    const [selectedBid, setSelectedBid] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [seat, setSeat] = useState('');
    const [minPoints, setMinPoints] = useState('');
    const [maxPoints, setMaxPoints] = useState('');

    useEffect(() => {
        const fetchAvailableBids = async () => {
            try {
                let url = `${API_BASE_URL}/api/bids/available/${agreementId}?parent_id=${parentBid}&initial_toggle=${toggleState}`;
                if (selectedBidId) {
                    url += `&selected_bid=${selectedBidId}`;
                }
                if (currentAdd) {
                    url += `&is_current=true`;
                }
                else {
                    url += `&is_current=false`;
                }
                const response = await axios.get(url, { withCredentials: true });
                setAvailableBids(response.data);
            } catch (error) {
                console.error("Error fetching available bids:", error);
            }
        };

        fetchAvailableBids().then();
    }, [parentBid, agreementId, toggleState, currentAdd, selectedBidId]);

    const handleCheckboxChange = (seat) => {
        setSeat(prevSeat => prevSeat === seat ? null : seat);
    };
    const handleMaxPointsBlur = () => {
        if (maxPoints !== '' && parseInt(maxPoints) < parseInt(minPoints)) {
            setMaxPoints(minPoints);
        }
    };

    const handleAdd = async () => {
        try {
            const data = {
                parent_bid: parentBid,
                bid_id: selectedBid,
                short_description: shortDescription,
                bid_identifier: seat ? `${seat} seat` : null,
                min_points: minPoints ? `${minPoints}` : null,
                max_points: maxPoints ? `${maxPoints}` : null,

            };
            axios({
                method: 'post',
                url: `${API_BASE_URL}/api/bids/${agreementId}?initial_toggle=${toggleState}`,
                data: data,
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken(),
                }
            })
                .then(response => {
                    onUpdateBids(response.data);
                })
            // Handle response here. For example, you can close the popup and refresh the bids list
            onClose();
        } catch (error) {
            console.error("Error adding bid:", error);

        }

};

    return (
        <div className="add-bid-popup" onClick={e => e.stopPropagation()}>
            <h4 className="popup-title">Add Bid</h4>
            <select value={selectedBid} onChange={e => setSelectedBid(e.target.value)}>
                <option value="">Select a bid</option>
                {availableBids.map((bid) => (
                    <option key={bid.id} value={bid.id}>
                        {bid.id === selectedBid ? `${bid.bid_name} (split)` : bid.bid_name}
                    </option>
                ))}
            </select>
            <input
                type="text"
                value={shortDescription}
                onChange={e => setShortDescription(e.target.value)}
                placeholder="Short description"
                maxLength={25}
            />
            <div className="popup-checkboxes">
                Position: &nbsp;
                <label>
                    <input type="checkbox" checked={seat === '1st/2nd'} onChange={() => handleCheckboxChange('1st/2nd')}
                           title="Keep unchecked if there is no seat specific details"/>
                    1st/2nd Seat
                </label>
                <label>
                    <input type="checkbox" checked={seat === '3rd/4th'} onChange={() => handleCheckboxChange('3rd/4th')}
                           title="Keep unchecked if there is no seat specific details"/>
                    3rd/4th Seat
                </label>
                <label>
                    <input type="checkbox" checked={seat === '4th'} onChange={() => handleCheckboxChange('4th')}
                           title="Keep unchecked if there is no seat specific details"/>
                    4th Seat
                </label>
            </div>
            <div className="popup-points">
                <label>
                    Min Points:
                    <input type="number" min="0" value={minPoints} onChange={e => setMinPoints(e.target.value)}/>
                </label>
                <label>
                    Max Points:
                    <input type="number" min="0" value={maxPoints} onChange={e => setMaxPoints(e.target.value)}
                           onBlur={handleMaxPointsBlur}/>
                </label>
            </div>
            <div className="popup-buttons">
                <button disabled={!selectedBid || !shortDescription} onClick={handleAdd}>Add</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
}

export default AddBidPopup;