import React, { useState, useEffect } from 'react';
import Agreements from "./components/Agreements";
import Bids from "./components/Bids";
import BidDetails from "./components/BidDetails";
import BidNotes from "./components/BidNotes";
import CommentsSection from "./components/CommentsSection";
import NotificationBell from "./components/Notifications/NotificationBell";

function MainBody({ userId, userInfo }) {
    const [selectedAgreementId, setSelectedAgreementId] = useState(null);
    const [selectedBidId, setSelectedBidId] = useState(null); // New state for selected bid ID
    // Implement logic to fetch agreement and set it in state

    useEffect(() => {
        setSelectedBidId(null)
    }, [selectedAgreementId]);

    return (
        <div className="main-body">
            <NotificationBell/>
            <div className="agreement-bid-box">
                <div className="agreement-container">
                    <Agreements userId={userId} onAgreementSelect={setSelectedAgreementId}/>
                </div>
                <div className="bid-wrapper">
                    <Bids agreementId={selectedAgreementId} onBidSelect={setSelectedBidId}/>
                </div>
            </div>
            {selectedBidId ? (
                <div className="bid-notes-box">
                    <div className="notes-details-box">
                        <BidNotes bidId={selectedBidId}/>
                    </div>
                    <div className="bids-details-box">
                        <BidDetails bidId={selectedBidId} userID={userId}/>
                    </div>
                    <div className="audit-details-box">

                    </div>
                </div>
            ) : null}
            {selectedBidId ? (
                <div className="comments-box">
                    <CommentsSection bidId={selectedBidId} userInfo={userInfo}/>
                </div>
            ) : null}

        </div>
    );
}

export default MainBody;