import React from 'react';

// Functional component to render the suit symbol
const SuitSymbol = ({ suit, color }) => (
    <span style={{ color: color }}>{suit}</span>
);

function processSuitText(description, shouldReturnComponent = true) {
    // Function to get the suit symbol component based on the character

    if (!description) {
        return shouldReturnComponent ? [] : '';
    }
    const getSuitSymbol = (char) => {
        switch (char.toLowerCase()) {
            case 'h':
                return shouldReturnComponent ? <SuitSymbol suit="♥️" color="red" /> : "♥️";
            case 'd':
                return shouldReturnComponent ? <SuitSymbol suit="♦️" color="red" /> : "♦️";
            case 's':
                return shouldReturnComponent ? <SuitSymbol suit="♠️" color="black" /> : "♠️";
            case 'c':
                return shouldReturnComponent ? <SuitSymbol suit="♣️" color="black" /> : "♣️";
            default:
                return char;
        }
    };

    const processedText = description.split(/(![hHdDsScC])/).map((part) => {
        if (part.startsWith('!')) {
            return getSuitSymbol(part[1]);
        }
        return part;
    });
    return shouldReturnComponent ? processedText : processedText.join('');

}

export default processSuitText;