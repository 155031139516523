import React, { useState, useEffect } from 'react';
import axios from 'axios';
import getCsrfToken from '../utils/getCsrfToken';
import { API_BASE_URL } from '../config/config';
import GroupBidDropdown from "./GroupBidDropdown";



 function UpdateBidPopup({ agreementId, bid, parentBid, onClose, toggleState, onUpdateBids }) {
    const [bidId, setBidId] = useState(bid.id); // New state for selected bid ID
    const [shortDescription, setShortDescription] = useState(bid.short_description);
    const [minPoints, setMinPoints] = useState(bid.min_points);
    const [maxPoints, setMaxPoints] = useState(bid.max_points);
    const [flagForDeletion, setFlagForDeletion] = useState(false);
    const [seat, setSeat] = useState('');
    const [linkedBids, setLinkedBids] = useState(false);
    const [dropdownData, setDropdownData] = useState([]); // State to store dropdown data
    const [selectedDropdownId, setSelectedDropdownId] = useState(bid.linked_to); // State for selected dropdown ID
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [groupBids, setGroupBids] = useState(false);
    const [availableGroups, setAvailableGroups] = useState([]);

     useEffect(() => {
         if (groupBids) {
             if (bid.grouping) {
                 // When groupBids is checked and bid.grouping is available
                 const splitOptions = bid.bid_name.split(',').map(option => option.trim());
                 setSelectedGroup(splitOptions);
             }
         } else {
             // When groupBids is unchecked or bid.grouping is not available
             setSelectedGroup([]);
         }
     }, [groupBids, bid]);


     useEffect(() => {
         const fetchAvailableBids = async () => {
             try {
                 let url = `${API_BASE_URL}/api/bids/available/${agreementId}?parent_id=${parentBid}&initial_toggle=1`;
                 if (bid.id) {
                     url += `&selected_bid=${bid.id}`;
                 }
                 url += `&is_current=true`;
                 const response = await axios.get(url, { withCredentials: true });
                 setAvailableGroups(response.data);
             } catch (error) {
                 console.error("Error fetching available bids:", error);
             }
         };

         fetchAvailableBids().then();
     }, [parentBid, agreementId, toggleState, bid]);

     useEffect(() => {
         setBidId(bid.id);
         setFlagForDeletion(false);
         setLinkedBids(false);
         setShortDescription(bid.short_description);
         setMinPoints(bid.min_points);
         setMaxPoints(bid.max_points);
         if (bid.delete) {
             setFlagForDeletion(true);
             setLinkedBids(false);
             setGroupBids(false);
         }
         if (bid.linked_to) {
             setLinkedBids(true);
             setSelectedDropdownId(bid.linked_to);
             setGroupBids(false);
         }
         if (bid.grouping) {
             setGroupBids(true);
             setLinkedBids(false);
         }
     }, [bid]);



     useEffect(() => {
         if (linkedBids) {
             axios.get(`${API_BASE_URL}/api/bids/description/${agreementId}?id=${bidId}`, {
                 withCredentials: true
             })
                 .then(response => {
                     setDropdownData(response.data);
                 })
                 .catch(error => console.error("Error fetching dropdown data:", error));
         }
     }, [linkedBids, agreementId, bidId]);
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            id: bidId,
            parent_bid: parentBid,
            short_description: flagForDeletion ? 'FLAG FOR DELETE' : shortDescription,
            bid_identifier: seat ? `${seat} seat` : null,
            min_points: minPoints ? `${minPoints}` : null,
            max_points: maxPoints ? `${maxPoints}` : null,
            delete: flagForDeletion,
            linked_to: linkedBids ? selectedDropdownId : null,
            group: selectedGroup,

        };
        try {
            axios({
                method: 'put',
                url: `${API_BASE_URL}/api/bids/${agreementId}?initial_toggle=${toggleState}`,
                data: data,
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken(),
                }
            })
                .then(response => {
                    onUpdateBids(response.data);
                })
            // Handle response here. For example, you can close the popup and refresh the bids list
            onClose();
        }
        catch (error) {
            console.error("Error updating bid:", error);
        }
    };
     const handleSeatCheckboxChange = (seat) => { // Add this function
         setSeat(prevSeat => prevSeat === seat ? null : seat);
     };
     const handleFlagForDeletionChange = (checked) => {
         setFlagForDeletion(checked);
         if (checked) {
             // If flagForDeletion is checked, set groupBids and linkedBids to false
             setGroupBids(false);
             setLinkedBids(false);
         }
     };
     const handleLinkedBidsChange = () => {
         const newLinkedBidsValue = !linkedBids;
         setLinkedBids(newLinkedBidsValue);
         if (newLinkedBidsValue) {
             // If linkedBids is checked, set groupBids to false
             setGroupBids(false);
         }
     };
     const handleGroupBidsChange = () => {
         const newGroupBidsValue = !groupBids;
         setGroupBids(newGroupBidsValue);
         if (newGroupBidsValue) {
             // If groupBids is checked, set linkedBids to false
             setLinkedBids(false);
         }
     };


    return (
        <div className="add-bid-popup" onClick={e => e.stopPropagation()}>
            <h4 className="popup-title">Update Bid</h4>
            <form onSubmit={handleSubmit}>
                <label>
                    Short Description:
                    <input type="text" value={shortDescription} onChange={e => setShortDescription(e.target.value)}
                           disabled={flagForDeletion}/>
                </label>
                <div className="popup-checkboxes">
                    Position: &nbsp;
                    <label>
                        <input type="checkbox" checked={seat === '1st/2nd'}
                               onChange={() => handleSeatCheckboxChange('1st/2nd')}
                               title="Keep unchecked if there is no seat specific details"/>
                        1st/2nd Seat
                    </label>
                    <label>
                        <input type="checkbox" checked={seat === '3rd/4th'}
                               onChange={() => handleSeatCheckboxChange('3rd/4th')}
                               title="Keep unchecked if there is no seat specific details"/>
                        3rd/4th Seat
                    </label>
                    <label>
                        <input type="checkbox" checked={seat === '4th'} onChange={() => handleSeatCheckboxChange('4th')}
                               title="Keep unchecked if there is no seat specific details"/>
                        4th Seat
                    </label>
                </div>
                <div className="popup-points">
                    <label>
                        Min Points:
                        <input type="number" value={minPoints} onChange={e => setMinPoints(e.target.value)}/>
                    </label>
                    <label>
                        Max Points:
                        <input type="number" value={maxPoints} onChange={e => setMaxPoints(e.target.value)}/>
                    </label>
                </div>
                <label>
                    Linked Bids:
                    <input type="checkbox" checked={linkedBids}
                           onChange={handleLinkedBidsChange} disabled={flagForDeletion}/>
                </label>
                {linkedBids && !flagForDeletion && (
                    <select value={selectedDropdownId || ''} onChange={e => setSelectedDropdownId(e.target.value)}>
                        <option value="">Select a Bid</option>
                        {dropdownData.map(linkedItem => (
                            <option key={linkedItem.id} value={linkedItem.id}>{linkedItem.short_description} - {linkedItem.bid_name}</option>
                        ))}
                    </select>
                )}
                <label>
                    Group Bids:
                    <input type="checkbox" checked={groupBids}
                           onChange={handleGroupBidsChange} disabled={flagForDeletion}/>
                </label>
                {groupBids && !flagForDeletion && (
                    <GroupBidDropdown
                        availableGroups={availableGroups}
                        selectedGroup={selectedGroup}
                        setSelectedGroup={setSelectedGroup}
                    />
                )}

                <label>
                    Flag for Deletion:
                    <input type="checkbox" checked={flagForDeletion}
                           onChange={e => handleFlagForDeletionChange(e.target.checked)}
                           title="This will flag the element for deletion, this will not happen right away to avoid wiping large amounts of data"/>
                </label>
                <div className="popup-buttons">
                    <button type="submit">Save</button>
                    <button type="button" onClick={onClose}>Cancel</button>
                </div>
            </form>
        </div>
    );
 }

export default UpdateBidPopup;