import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from "../../config/config";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setMessage('Invalid email address');
            return;
        }

        try {
            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/user/forgot-password/`,
                data: { email }
            });

            if (response.status === 200) {
                setMessage('If you provided a valid email, a link will be sent to reset your password');
            }
        } catch (error) {
            console.log('Forgot password error: ', error);
        }
    };

    return (
        <div className="forgot-password-form-container">
            <h2>Forgot Password</h2>
            <form onSubmit={handleForgotPassword}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                />
                <button type="submit">Send</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default ForgotPassword;