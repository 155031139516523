import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from "../../config/config";
import { useSearchParams, useNavigate } from 'react-router-dom';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }
        if (password.length < 8) {
            setMessage('Password must be at least 8 characters long');
            return;
        }

        try {
            const response = await axios({
                method: 'put',
                url: `${API_BASE_URL}/user/reset-password/`,
                data: { token, password },
            });

            if (response.status === 200) {
                setMessage('Password has been reset successfully, you will be brought to the main page shortly');
                setTimeout(() => {
                    navigate('/'); // Navigate to the front page after 2 seconds
                }, 2000);
            }
        } catch (error) {
            console.log('Reset password error: ', error);
            setMessage('Something went wrong'); // Set error message
        }
    };

    return (
        <div className="reset-password-form-container">
            <h2>Reset Password</h2>
            <form onSubmit={handleResetPassword}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="New Password"
                />
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                />
                <button type="submit">Reset Password</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default ResetPassword;