import React, {useCallback, useEffect, useState, useRef } from 'react';
import '../styles/AgreementBid.css'
import axios from "axios";
import {API_BASE_URL} from '../config/config';
import AddBidPopup from "./AddBidPopup";
import UpdateBidPopup from "./UpdateBidPopup";
import processSuitText from "../utils/processSuitText";


function Bids( { agreementId, onBidSelect } ) {
    const [currentBids, setCurrentBids] = useState([]);
    const [nextBids, setNextBids] = useState([]);
    const [selectedBidId, setSelectedBidId] = useState(null);
    const [parentBidId, setParentBidId] = useState(0);
    const [nextparentBidId, setNextParentBidId] = useState(null);
    //using this to track the path the user navigates
    const [bidChain, setBidChain] = useState([
        {bids: currentBids, parent_bid: null, selected_path: null},
        {bids: nextBids, parent_bid: null, selected_path: null}
    ]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentAdd, setCurrentAdd] = useState(true);
    const [toggleState, setToggleState] = useState(1);
    const [currentGroupedBids, setCurrentGroupedBids] = useState({});
    const [nextGroupedBids, setNextGroupedBids] = useState({});
    const [parentGroupedBids, setParentGroupedBids] = useState({});
    const [selectedBidForUpdate, setSelectedBidForUpdate] = useState(null);
    const [isCurrentBidLinked, setIsCurrentBidLinked] = useState(false);

    const refs = useRef([]);
    const createRef = (id) => {
        if (!refs.current[id]) {
            refs.current[id] = React.createRef();
        }
    };
    const scrollToBid = (bidId) => {
        const ref = refs.current[bidId];
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const handleToggleChange = () => {
        setToggleState(toggleState === 1 ? 2 : 1);
    };



    const fetchBids = useCallback(async (bidId = null) => {
        if (agreementId) {
            try {
                const url = bidId
                    ? `${API_BASE_URL}/api/bids/${agreementId}?bid_filter=${bidId}`
                    : `${API_BASE_URL}/api/bids/${agreementId}?initial_toggle=${toggleState}`

                const response = await axios.get(url, { withCredentials: true });
                return response.data;


            } catch (error) {
                console.error("Error fetching bids:", error);
            }
        }
    }, [agreementId, toggleState]);

    useEffect(() => {
        fetchBids().then(newBids => {
            if (newBids && newBids.current_bids) { // Check if newBids and newBids.current_bids are defined
                setSelectedBidId(null);
                setNextBids([]);
                setCurrentBids(newBids.current_bids);
                setParentBidId(0);

            }
        });
    }, [fetchBids]);

    useEffect(() => {
        if (bidChain[bidChain.length - 3] && bidChain[bidChain.length - 3].selected_path) {
            scrollToBid(bidChain[bidChain.length - 3].selected_path);
        }
        if (bidChain[bidChain.length - 2] && bidChain[bidChain.length - 2].selected_path) {
            scrollToBid(bidChain[bidChain.length - 2].selected_path);
        }
    }, [bidChain]);

    useEffect(() => {
        currentBids.sort(sortBids);
        setCurrentGroupedBids(groupByBidName(currentBids));
        if (nextBids) {
            nextBids.sort(sortBids);
            setNextGroupedBids(groupByBidName(nextBids));
        }
        if (bidChain[bidChain.length - 3]) {
            bidChain[bidChain.length - 3].bids.sort(sortBids);
            setParentGroupedBids(groupByBidName(bidChain[bidChain.length - 3].bids));
        }
        else {
            setParentGroupedBids({});
        }
    }, [currentBids, nextBids, bidChain]);

    useEffect(() => {
        const newBidChain = [...bidChain];
        if (nextBids)
            newBidChain[newBidChain.length - 1].bids = nextBids;
        else {
            setNextBids([])
            newBidChain[newBidChain.length - 1].bids = [];
        }
        newBidChain[newBidChain.length - 1].parent_bid = nextparentBidId;
        newBidChain[newBidChain.length - 1].selected_path = null;
        newBidChain[newBidChain.length - 2].bids = currentBids;
        newBidChain[newBidChain.length - 2].parent_bid = parentBidId;
        newBidChain[newBidChain.length - 2].selected_path = selectedBidId;
        setBidChain(newBidChain);

    }, [nextBids, currentBids, parentBidId, selectedBidId, nextparentBidId]);

    useEffect(() => {
        // Reset bidChain to its default state
        setBidChain([
            {bids: [], parent_bid: null, selected_path: null},
            {bids: [], parent_bid: null, selected_path: null}
        ]);
    }, [agreementId, toggleState]);

    function getSuitValue(suit) {
        switch (suit) {
            case 'C':
                return 1;
            case 'D':
                return 2;
            case 'H':
                return 3;
            case 'S':
                return 4;
            case 'NT':
                return 5;
            default:
                return 0;
        }
    }

    function sortBids(a, b) {
        let aBidName = a.bid_name.split(',')[0]; // Split on comma and take the first element
        let bBidName = b.bid_name.split(',')[0];

        const aNumeric = aBidName.match(/\d+/); // Extracts the numeric part
        const bNumeric = bBidName.match(/\d+/);

        // Handle cases where numeric part is not found
        if (!aNumeric || !bNumeric) {
            return 0;
        }

        const aValue = parseInt(aNumeric[0]);
        const bValue = parseInt(bNumeric[0]);
        const aSuit = aBidName.replace(aValue, '');
        const bSuit = bBidName.replace(bValue, '');

        if (aValue < bValue) {
            return -1;
        } else if (aValue > bValue) {
            return 1;
        } else {
            return getSuitValue(aSuit) - getSuitValue(bSuit);
        }
    }

    function getBidPath() {
        let bidNames = [];
        for (let i = 0; i < bidChain.length - 1; i++) {
            let selectedPath = bidChain[i].selected_path;
            let bidName = bidChain[i].bids.find(bid => bid.id === selectedPath)?.bid_name;
            if (bidName) {
                // Add <strong> tags to make the text bold
                if (toggleState === 2) {
                    bidName = i % 2 !== 0 ? `<strong>${bidName}</strong>` : bidName;
                } else {
                    bidName = i % 2 === 0 ? `<strong>${bidName}</strong>` : bidName;
                }
                bidNames.push(bidName);
            }
        }
        // Use '  ' instead of '->' after the first element when toggleState is 2
        const separator = toggleState === 2 ? '  ' : ' -> ';
        return bidNames.join(separator);
    }

    const updateBidsArray = (updatedBids) => { // When a new bid is added, this function is called to update the bids array
        if (currentAdd) {
            setCurrentBids(updatedBids);
            fetchBids(selectedBidId).then();
        } else {
            setNextBids(updatedBids);
        }
    };

    function addCurrentToBidChain() {
        // As people progress this makes a deepcopy to save on the db
        const deepCopy = JSON.parse(JSON.stringify(bidChain[bidChain.length - 2]));
        setBidChain([...bidChain.slice(0, -2), deepCopy, ...bidChain.slice(-2)]);
    }
    const addBid = (current = true) => {
        setIsPopupOpen(true);
        bidChain[bidChain.length - 2].bids = currentBids // Need to set this because the initial setting doesn't link it
        setCurrentAdd(current);
    };

    const updateBid = (bidId, isCurrentBid) => {
        let bidToUpdate;
        if (isCurrentBid) {
            // Remove bid from currentBids
            bidToUpdate = currentBids.find(bid => bid.id === bidId);
        } else {
            // Remove bid from nextBids
            bidToUpdate = nextBids.find(bid => bid.id === bidId);
        }
        setSelectedBidForUpdate(bidToUpdate);
    };
    const groupByBidName = (bids) => {
        if (!Array.isArray(bids)) {
            // Handle the case where bids is not an array
            return {};
        }
        return bids.reduce((currentGroupedBids, bid) => {
            (currentGroupedBids[bid.bid_name] = currentGroupedBids[bid.bid_name] || []).push(bid);
            return currentGroupedBids;
        }, {});
    };


    const selectBid = (userSelectedBidId) => {
        setSelectedBidId(userSelectedBidId);
        onBidSelect(userSelectedBidId);
        const bid = bidChain[bidChain.length - 2].bids.find(bid => bid.id === userSelectedBidId);
        if (bid) {
            if (bid.linked_to) {
                setIsCurrentBidLinked(true);
            }
            else {
                setIsCurrentBidLinked(false);
            }
        }

        // Check if the last element in bidChain has bids and contains the selected bid ID
        if (bidChain[bidChain.length - 1].bids.length > 0 &&
            bidChain[bidChain.length - 1].bids.some(bid => bid.id === userSelectedBidId)) {
            addCurrentToBidChain();
            setCurrentBids(nextBids);
            setParentBidId(nextparentBidId);
            fetchBids(userSelectedBidId).then(newBids => {
                setNextBids(newBids.next_bids);
                setNextParentBidId(newBids.parent_bid_id)
            });
        }
        // Check if there are more than two elements in bidChain and if the third-last element contains the selected bid ID
        else if (bidChain.length > 2 &&
            bidChain[bidChain.length - 3].bids &&
            bidChain[bidChain.length - 3].bids.some(bid => bid.id === userSelectedBidId)) {
                setCurrentBids(JSON.parse(JSON.stringify(bidChain[bidChain.length - 3].bids)));
                setParentBidId(bidChain[bidChain.length - 3].parent_bid);
                bidChain.splice(-3, 1);  // Removes the 2nd last chain which was the current bids

            fetchBids(userSelectedBidId).then(newBids => {
                setNextBids(newBids.next_bids);
                setNextParentBidId(newBids.parent_bid_id)
            });
        }
        else {
            fetchBids(userSelectedBidId).then(newBids => {
                setNextBids(newBids.next_bids);
                setNextParentBidId(newBids.parent_bid_id)
            });
        }



    };


    return (
        <div className="outline">
            <div className="bid-container">
                {isPopupOpen && (
                    <>
                        <div className="overlay" onClick={() => setIsPopupOpen(false)}></div>
                        <AddBidPopup
                            agreementId={agreementId}
                            parentBid={currentAdd ? parentBidId : nextparentBidId}
                            bidsArray={currentAdd ? currentBids : nextBids}
                            onClose={() => setIsPopupOpen(false)}
                            onUpdateBids={updateBidsArray}
                            toggleState={toggleState}
                            currentAdd={currentAdd}
                            selectedBidId={selectedBidId}
                        />
                    </>
                )}
                {selectedBidForUpdate && (
                    <div className="overlay">
                        <UpdateBidPopup
                            agreementId={agreementId}
                            parentBid={currentAdd ? parentBidId : nextparentBidId}
                            bid={selectedBidForUpdate}
                            onClose={() => setSelectedBidForUpdate(null)}
                            toggleState={toggleState}
                            onUpdateBids={updateBidsArray}
                        />
                    </div>
                )}


                {agreementId ? (

                    <div className="bids">

                        {/* Parent Bids */}
                        <div className="parent-bids">
                            {bidChain[bidChain.length - 3] && parentGroupedBids && Object.entries(parentGroupedBids).map(([parentBidName, parentBids]) => (
                                <div
                                    className="bid-box"
                                >
                                    <h5><span>{processSuitText(parentBidName)}</span></h5>
                                    <div className="bids">
                                        {parentBids.map(parentBid => {
                                            createRef(parentBid.id);
                                            return (
                                                <div
                                                    key={parentBid.id}
                                                    onClick={() => selectBid(parentBid.id)}
                                                    ref={refs.current[parentBid.id]}
                                                    className={`bid ${selectedBidId === parentBid.id ? 'selected' : ''} ${bidChain[bidChain.length - 3].selected_path === parentBid.id ? 'highlighted' : ''}${parentBid.delete ? 'deleted-style' : ''}`}
                                                >
                                                    <p>{processSuitText(parentBid.short_description)} {parentBid.linked_to ? <span>&#x1F517;</span> : null}</p>
                                                    <div className="bid-tags">
                                                        {parentBid.min_points ? (
                                                            <p>{parentBid.min_points} to {parentBid.max_points || '+'}</p>
                                                        ) : null
                                                        }
                                                        <p>{parentBid.bid_identifier}</p>

                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                            ))}
                        </div>

                        {/* Current Bids */}
                        <button className="add-bid-button" onClick={addBid}>+</button>
                        <div className="current-bids">

                            {currentGroupedBids && Object.entries(currentGroupedBids).map(([currentBidName, currentBids]) => (
                                <div
                                    className="bid-box"
                                >
                                    <h5><span>{processSuitText(currentBidName)}</span></h5>
                                    <div className="bids" >
                                        {currentBids.map(currentBid => {
                                            createRef(currentBid.id);
                                            return (
                                                <div
                                                    key={currentBid.id}
                                                    onClick={() => selectBid(currentBid.id)}
                                                    ref={refs.current[currentBid.id]}
                                                    className={`bid ${selectedBidId === currentBid.id ? 'selected' : ''} ${bidChain[bidChain.length - 2].selected_path === currentBid.id ? 'highlighted' : ''} ${currentBid.delete ? 'deleted-style' : ''}`}
                                                >
                                                    <p>{processSuitText(currentBid.short_description)} {currentBid.linked_to ? <span>&#x1F517;</span> : null}</p>
                                                    <div className="bid-tags">
                                                        {currentBid.min_points ? (
                                                            <p>{currentBid.min_points} to {currentBid.max_points || '+'} HCP</p>
                                                        ) : null
                                                        }
                                                        <p>{currentBid.bid_identifier}</p>

                                                    </div>
                                                    <button className="update-bid-button"
                                                            onClick={() => updateBid(currentBid.id, true)}>&#9998;
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                            ))}
                        </div>

                        {/* Next Bids */}
                        {selectedBidId ? (
                            <button className="add-bid-button" onClick={() => addBid(false)} disabled={isCurrentBidLinked}>+</button>
                        ) : null}
                        <div className="next-bids">
                            {selectedBidId ? null : (
                                <div className="empty-message">Please select a bid to see what is available</div>
                            )}
                            {nextGroupedBids && Object.entries(nextGroupedBids).map(([nextBidName, nextBids]) => (
                                <div
                                    className="bid-box"
                                >
                                    <h5><span>{processSuitText(nextBidName)}</span></h5>
                                    <div className="bids">
                                        {nextBids.map(nextBid => {
                                            createRef(nextBid.id);
                                            return (
                                                <div
                                                    key={nextBid.id}
                                                    onClick={() => selectBid(nextBid.id)}
                                                    ref={refs.current[nextBid.id]}
                                                    className={`bid ${selectedBidId === nextBid.id ? 'selected' : ''} ${bidChain[bidChain.length - 2].selected_path === nextBid.id ? 'highlighted' : ''}${nextBid.delete ? 'deleted-style' : ''}`}
                                                >
                                                    <p>{processSuitText(nextBid.short_description)} {nextBid.linked_to ? <span>&#x1F517;</span> : null}</p>
                                                    <div className="bid-tags">
                                                        {nextBid.min_points ? (
                                                            <p>{nextBid.min_points} to {nextBid.max_points || '+'} HCP</p>
                                                        ) : null}
                                                        <p>{nextBid.bid_identifier}</p>
                                                    </div>
                                                    <button className="update-bid-button"
                                                            onClick={() => updateBid(nextBid.id, false)}>&#9998;
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="empty-message">Please select or create a new agreement</div>
                )}
                {/* Bids Toggle */}


            </div>
            <div className="bid-options">
                {agreementId ? (
                    <div className="bid-toggle">
                        <h5>Opener</h5>
                        <label className="switch">
                            <input type="checkbox" checked={toggleState === 2} onChange={handleToggleChange}/>
                            <span className="slider round"></span>
                        </label>
                        <div>{toggleState === 1 ? 'Us' : 'Them'}</div>
                    </div>

                ) : null}
                <div className="bid-path" dangerouslySetInnerHTML={{__html: getBidPath()}}/>
                <div className="spacer"></div>
            </div>
        </div>


    );

}

export default Bids;
