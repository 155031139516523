import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {API_BASE_URL} from "../config/config";
import '../styles/CommentsSection.css';
import getCsrfToken from "../utils/getCsrfToken";
import processSuitText from "../utils/processSuitText";

function CommentsSection( { bidId, userInfo } ) {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/api/comments/${bidId}`,
                data: { text: newComment },
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            });
            setComments([response.data, ...comments]);
            setNewComment('');
        } catch (error) {
            console.log('Failed to post comment', error);
        }
    };

    useEffect(() => {
        // Fetch notes from the backend when bidNum changes
        axios.get(`${API_BASE_URL}/api/comments/${bidId}`, { withCredentials: true } )
            .then(response => {
                setComments(response.data)
                setNewComment('')
            })
            .catch(error => {
                console.error('Error fetching notes:', error);
            });
    }, [bidId]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevents the default action of a newline in a multi-line input or form submission
            handleSubmit(event);
        }
    };

    return (
        <div className="comments-section">
            <h3>Discussion</h3>
            <div className="form-container">
                <input
                    type="text"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="comment-input"
                    placeholder="Type a message..."
                />
                <button type="submit" className="text-submit-button" onClick={handleSubmit}>Send</button>
            </div>
            {comments.map((comment) => {
                const date = new Date(comment.created_at);
                const formattedDate = date.toLocaleString(undefined, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                });
                const commentInfo = `${comment.comment_str} on ${formattedDate}`;
                const isCurrentUser = comment.comment_str.includes(userInfo.username); // Assuming the username is part of the comment string
                const commentClass = isCurrentUser ? "comment right" : "comment left";

                return (
                    <div key={comment.id} className={commentClass}>
                        <div className="comment-bubble">
                            <p className="comment-text">{processSuitText(comment.text)}</p>
                            <p className="comment-info">{commentInfo}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default CommentsSection;