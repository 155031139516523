import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config/config';
import getCsrfToken from '../utils/getCsrfToken';
import '../styles/AgreementBid.css';


function Agreements({userId, onAgreementSelect} ) {
    const [agreements, setAgreements] = useState([]);
    const [selectedAgreementId, setSelectedAgreementId] = useState(null);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [newAgreementName, setNewAgreementName] = useState('');
    const [showEmailDialog, setShowEmailDialog] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [invites, setInvites] = useState([]);

    const fetchInvites = useCallback(() => {
        axios.get(`${API_BASE_URL}/api/invite/${userId}`, { withCredentials: true })
            .then(response => setInvites(response.data))
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    setInvites([]);
                } else {
                    console.error('Error fetching invites', error);
                }
            });
    }, [userId]);

    const fetchAgreements = useCallback(() => {
    axios.get(`${API_BASE_URL}/api/agreements/${userId}`, { withCredentials: true })
        .then(response => setAgreements(response.data))
        .catch(error => console.error('Error fetching agreements', error));
    }, [userId]);

    useEffect(() => {
        fetchAgreements()
        fetchInvites()
    }, [fetchAgreements, fetchInvites]);


    const addAgreement = () => {
        const newAgreement = { agreement_name: newAgreementName };
        axios({
            method: 'post',
            url: `${API_BASE_URL}/api/agreements/${userId}`,
            data: newAgreement,
            withCredentials: true,
            headers: {
                'X-CSRFToken': getCsrfToken(),
            }
        })
        .then(response => {
            setAgreements([...agreements, response.data]);
            setShowAddDialog(false);
            setNewAgreementName('');
            fetchAgreements()
        })
        .catch(error => console.error('Error adding agreement', error));
    };
    const invitePartner = () => {
        // Make an API call to send the email
        axios({
            method: 'post',
            url: `${API_BASE_URL}/api/invite/${selectedAgreementId}`,
            data: { email: emailAddress },
            withCredentials: true,
            headers: {
                'X-CSRFToken': getCsrfToken(),
            }
        })
            .then(() => {
                setShowEmailDialog(false);
                setEmailAddress('');
            })
            .catch(error => console.error('Error sending email', error));
    };

    const deleteAgreement = () => {
        if (window.confirm('Are you sure you want to delete this agreement?')) {
            axios({
                method: 'delete',
                url: `${API_BASE_URL}/api/agreements/${userId}?agreement_id=${selectedAgreementId}`,
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken(),
                }
            })
            .then(() => {
                setAgreements(agreements.filter(agreement => agreement.id !== selectedAgreementId));
                setSelectedAgreementId(null);
                onAgreementSelect(null);
                fetchAgreements()
            })
            .catch(error => console.error('Error deleting agreement', error));
        }
    };
    const updateInvite = (inviteAgreement, response) => {
        console.log(response)
        axios({
            method: 'delete',
            url: `${API_BASE_URL}/api/invite/${inviteAgreement}`,
            data: { response },
            withCredentials: true,
            headers: {
                'X-CSRFToken': getCsrfToken(),
            }
        })
            .then(() => {
                fetchAgreements()
                fetchInvites()
            })
            .catch(error => console.error('Error updating invite', error));
    };

    return (
        <div className="agreement-box">
            <button className="agreement-button" onClick={deleteAgreement} disabled={!selectedAgreementId}>[-] Delete Agreement</button>
            {agreements.map(agreement => (
                <div
                    key={agreement.id}
                    onClick={() => {
                        setSelectedAgreementId(agreement.id);
                        onAgreementSelect(agreement.id);
                    }}
                    className={`agreement-item ${agreement.id === selectedAgreementId ? 'selected-convention' : ''}`}
                >
                    {agreement.agreement_name} {agreement.partner_name ?
                    <span style={{fontSize: '14px'}}> ({agreement.partner_name})</span>
                    : <button className="email-button" onClick={() => {setShowEmailDialog(true); setShowAddDialog(false)}} title="Invite Partner">📧</button>
                }
                </div>
            ))}
            <button className="agreement-button" onClick={() => {setShowAddDialog(true); setShowEmailDialog(false);}}>[+] Add Agreement</button>
            {invites.length > 0 && (
                <>
                    <h4>Pending Agreement Invites</h4>
                    {invites.map(invite => (
                        <div className="invite-item" key={invite.id}>
                            <div className="invite-text">
                            {invite.agreement_name}
                            <span className="small-text"> by {invite.creator_name} ({invite.creator_username})</span>
                            </div>
                            <div className="invite-buttons">
                                <button className="accept-button" onClick={() => updateInvite(invite.agreement, true)}>&#10003;</button>
                                <button className="decline-button" onClick={() => updateInvite(invite.agreement, false)}>&#10005;</button>
                            </div>
                        </div>
                    ))}
                </>
            )}
            {showEmailDialog && (
                <div className="email-dialog">
                    <input
                        type="email"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        placeholder="Enter email address"
                    />
                    <button onClick={invitePartner}>Send Email</button>
                    <button onClick={() => setShowEmailDialog(false)}>Cancel</button>
                </div>
            )}
            {showAddDialog && (
                <div className="add-dialog">
                    <input
                        type="text"
                        value={newAgreementName}
                        onChange={(e) => setNewAgreementName(e.target.value)}
                        placeholder="Enter agreement name"
                    />
                    <button onClick={addAgreement}>Submit</button>
                    <button onClick={() => setShowAddDialog(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
}

export default Agreements;