import React, {useState} from 'react';
import Header from './Header';
import MainBody from './MainBody';
import LandingPage from './LandingPage';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EmailActivation from './components/Login/EmailActivation';
import ForgotPassword from "./components/Login/ForgotPassword";
import ResetPassword from "./components/Login/ResetPassword";
import PrivacyPolicy from "./PrivacyPolicy";


function App() {
  const [userId, setUserId] = useState(null);
  const [userInfo, setUserInfo] = useState(null);


    return (
        <div className="App">
            <Router>
                <Header onUserIdChange={setUserId} onUserUpdate={setUserInfo} />
                <Routes>
                    <Route path="/account/activate" element={<EmailActivation />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    {/* Define a Route for your LandingPage or MainBody based on userId */}
                    <Route
                        path="/" element={userId ? <MainBody userId={userId} userInfo={userInfo} /> : <LandingPage />}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;

