import React from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config/config';
import getCsrfToken from '../utils/getCsrfToken';
import '../styles/NotesBidHistory.css';

function ReviewModal({ differences, unapprovedId, onClose, setReload }) {
    const formatText = (type, text) => {
        if (text === null) {
            return <em>No text</em>; // Display "No text" when text is null
        }
        if (type === 'insert') {
            return <span style={{ color: 'green', fontWeight: 'bold' }}>{text}</span>;
        } else if (type === 'delete') {
            return <del style={{ color: 'red' }}>{text}</del>;
        } else {
            return <span>{text}</span>;
        }
    };

    const handleBackgroundClick = (e) => {
        if (e.target.className === 'modal-background') {
            onClose();
        }
    };

    const handleApprove = () => {
        axios({
            method: 'put',
            url: `${API_BASE_URL}/api/bid/details/${unapprovedId}`,
            data: { approved: true },
            withCredentials: true,
            headers: {
                'X-CSRFToken': getCsrfToken(),
            }
        }).then(() => {
                setReload(prevReload => prevReload + 1);
                onClose();
            })
        .catch(error => console.error('Error approving bid', error));
    };

    const handleReject = () => {
        axios({
            method: 'put',
            url: `${API_BASE_URL}/api/bid/details/${unapprovedId}`,
            data: { approved: false },
            withCredentials: true,
            headers: {
                'X-CSRFToken': getCsrfToken(),
            }
        }).then(() => {
            setReload(prevReload => prevReload + 1);
            onClose();
        })
        .catch(error => console.error('Error rejecting bid', error));
    };

    return (
        <div className="modal-background" onClick={handleBackgroundClick}>
            <div className="bid-review-container">
                {Object.entries(differences).map(([key, changes]) => (
                    <div key={key}>
                        <h4>{key}</h4>
                        <span> {/* Wrap text segments in a span to keep them inline */}
                            {changes.map((change, index) => {
                                const [type, text] = change;
                                return (
                                    <React.Fragment key={index}>
                                        {formatText(type, text)}
                                    </React.Fragment>
                                );
                            })}
                        </span>
                    </div>
                ))}
                <div className="bid-review-buttons">
                    <button onClick={handleApprove}>Approve</button>
                    <button onClick={handleReject}>Reject</button>
                </div>
            </div>
        </div>
    );
}

export default ReviewModal;