import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from "../../config/config";


const EmailActivation = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [goodMessage, setGoodMessage] = useState('');

    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            axios.put(`${API_BASE_URL}/user/account/activate/`, { token }
            )
                .then(() => {
                    setTimeout(() => {
                        setGoodMessage('Account activated successfully, you will be brought to the main page shortly')
                        navigate('/'); // Navigate to the front page after 2 seconds
                    }, 2000);
                })
                .catch(error => {
                    // Handle activation failure
                    setError(true);
                    console.log(error)
                });
        }
    }, [searchParams, navigate]);


    if (error) {
        return (
            <div className="email-activation-container">
                <p>Something went wrong. You may need to recreate the user or contact the administrator</p>
            </div>
        );
    }

    // Optionally, show a loading indicator while the activation is in progress
    return (
        <div className="email-activation-container">
            {goodMessage ? <div>{goodMessage}</div> : <div>Activating your account...</div>}
        </div>
    );
};

export default EmailActivation;