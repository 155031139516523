import React from 'react';

function PrivacyPolicy() {
  return (
    <div className='privacy-policy-container'>
        <title>Privacy Policy</title>
        <h1>Privacy Policy</h1>

        <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information. By using our website, you consent to the practices described in this policy.</p>

        <h2>Information Collection</h2>
        <p>We may collect personal information when you interact with our website, such as when you sign up for an account or contact us. This information may include your name, email address, and any other information you provide.</p>

        <h2>Use of Information</h2>
        <p>We will not share your personal information with anyone unless directed by you or required by law.</p>

        <h2>Security</h2>
        <p>We take reasonable precautions to protect your information. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>

        <h2>Changes to this Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy, please <a href="mailto:atroposfate@gmail.com">contact us</a>.</p>
    </div>
  );
}

export default PrivacyPolicy;