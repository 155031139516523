import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config/config';
import '../styles/NotesBidHistory.css';
import processSuitText from '../utils/processSuitText';
import getCsrfToken from '../utils/getCsrfToken';
import BidReviewModal from './BidReviewModal';


function BidDetails({ bidId, userID }) {
    const [bidDetails, setBidDetails] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [showInterfere, setShowInterfere] = useState(false);
    const [unapprovedData, setUnapprovedData] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [submitVisible, setSubmitVisible] = useState(false);
    const [bidMeaning, setBidMeaning] = useState('');
    const [interfereMeaning, setInterfereMeaning] = useState('');
    const [interfereDouble, setInterfereDouble] = useState('');
    const [interfereCuebid, setInterfereCuebid] = useState('');
    const [reload, setReload] = useState(0);
    const [differences, setDifferences] = useState({});
    const [showReviewModal, setShowReviewModal] = useState(false);
    const [override, setOverride] = useState(false);

    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/bid/details/${bidId}`, { withCredentials: true })
            .then(response => {
                if (response.data.approved) {
                    setBidDetails(response.data.approved);
                    setShowInterfere(!!response.data.approved.interfere_meaning || !!response.data.approved.interfere_cuebid || !!response.data.approved.interfere_double);
                }
                else {
                    setBidDetails({});
                    setShowInterfere(false)

                }
                if (response.data.unapproved) { // Check if unapproved data exists
                    setUnapprovedData(response.data.unapproved); // Set unapproved data
                }
                else {
                    setUnapprovedData(null); // Reset unapproved data
                }
                if (response.data.differences) {
                    setDifferences(response.data.differences);
                }
                else {
                    setDifferences({});
                }
            })
            .catch(error => console.error('Error fetching bid details', error));
    }, [bidId, reload]);

    useEffect(() => {
        if (isEditable && isChanged) {
            setSubmitVisible(true);
            setOverride(false)
        }
        else {
            setSubmitVisible(false);
        }
    }, [isEditable, isChanged]);
    const handleEditClick = () => {
        if (!isEditable) {
            setBidMeaning(bidDetails.bid_meaning || '');
            setInterfereMeaning(bidDetails.interfere_meaning || '');
            setInterfereDouble(bidDetails.interfere_double || '');
            setInterfereCuebid(bidDetails.interfere_cuebid || '');
        }
        setIsEditable(!isEditable);
    };

    const handleSubmitClick = () => {
        setIsEditable(false);
        setSubmitVisible(false);
        setIsChanged(false);
        const data = {
            bid_meaning: bidMeaning,
            interfere_meaning: interfereMeaning || null,
            interfere_double: interfereDouble || null,
            interfere_cuebid: interfereCuebid || null,
            override: override,
        };
        axios.post(`${API_BASE_URL}/api/bid/details/${bidId}`, data, {
            withCredentials: true,
            headers: {
                'X-CSRFToken': getCsrfToken(),
            }
        })
            .then(() => {
                setReload(reload + 1);
                setOverride(false)
            })
            .catch(error => {
                console.log("Error updating bid details: ", error);
            });
    };


    return (
        <div className="bid-details-container">
            <div className="bid-details">
                <div className="bid-details-header">
                    <h3>Bid Details</h3>
                    <textarea value={isEditable ? bidMeaning : processSuitText(bidDetails.bid_meaning, false) || ''}
                              readOnly={!isEditable}
                              onChange={e => {setIsChanged(true); setBidMeaning(e.target.value);}}/>
                </div>
                <div className="interference-details">
                    {showInterfere && (
                        <>
                            <h4>Interference</h4>
                            <textarea
                                value={isEditable ? interfereMeaning : processSuitText(bidDetails.interfere_meaning, false) || ''}
                                readOnly={!isEditable}
                                onChange={e => {setIsChanged(true); setInterfereMeaning(e.target.value);}}/>
                            <h5>Cuebid</h5>
                            <textarea
                                value={isEditable ? interfereCuebid : processSuitText(bidDetails.interfere_cuebid, false) || ''}
                                readOnly={!isEditable}
                                onChange={e => {setIsChanged(true); setInterfereCuebid(e.target.value);}}/>
                            <h5>Double</h5>
                            <textarea
                                value={isEditable ? interfereDouble : processSuitText(bidDetails.interfere_double, false) || ''}
                                readOnly={!isEditable}
                                onChange={e => {setIsChanged(true); setInterfereDouble(e.target.value);}}/>
                        </>
                    )}
                </div>
            </div>
            <button onClick={handleEditClick}>{isEditable ? 'Lock' : 'Edit'}</button>
            {submitVisible && (
                <>
                    <button onClick={handleSubmitClick}>Submit</button>
                    <label>
                        Override Approval Process
                        <input type="checkbox" checked={override} onChange={() => setOverride(!override)}/>
                    </label>

                </>
            )}
            <label>
            Show Interfere Boxes
                <input type="checkbox" checked={showInterfere} onChange={() => setShowInterfere(!showInterfere)}/>
            </label>
            {unapprovedData && (userID === unapprovedData.created_by ?
                    <p>Revisions Submitted to Partner</p> :
                    <button onClick={() => setShowReviewModal(true)}>Review Pending Revisions</button>
            )}
            {showReviewModal && <BidReviewModal differences={differences} unapprovedId={unapprovedData.id} onClose={() => setShowReviewModal(false)} setReload={setReload} />}
        </div>
    );
}

export default BidDetails;