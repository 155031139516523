import React from 'react';
import { Link } from 'react-router-dom';


function LandingPage() {
  return (
      <div className="landing-page">
          <div className="landing-page-text">
              <h1>Welcome to Our Bridge Bids</h1>
              This site is for people and their partners to track their more complicated bridge systems.
              <br/>
              <br/>
              If you feel like buying me a coffee feel free to use this link it would help maintain the servers
              <a href="https://www.paypal.com/donate/?business=SDPXFXCXT5H5Y&no_recurring=0&currency_code=CAD"
                 target="_blank" rel="noreferrer">
                  <button className="donate-button">Donate</button>
              </a>
              <br/>
              <p>I have also created another site that analyzes some play in clubs if you want to check it out:</p>
              <a href="https://www.bridgeplaypal.com" target="_blank" rel="noopener noreferrer">
                  Visit BridgePlayPal.com
              </a>
              {/* Features Section */}
              <section id="features" className="features">
                  <div className="feature">
                      <img src="/feature.png" alt="Feature Icon" className="feature-logo"/>
                      <h2>Coordination with Partner</h2>
                      <p>A way to share your bids with your partner. Setups up an review and approval process so that
                          you and your partner can agree to the changes and meanings</p>
                  </div>
                  <div className="feature">
                      <img src="/feature.png" alt="Feature Icon" className="feature-logo"/>
                      <h2>Link Bids</h2>
                      <p>When bids have the same meaning or if the same convention can be used in different paths you
                          are able to link those bids to avoid duplication and the meaning changes.
                          Probably best to keep the linked bids on the lowest level so you can go back to the original
                          easier</p>
                  </div>
                  <div className="feature">
                      <img src="/feature.png" alt="Feature Icon" className="feature-logo"/>
                      <h2>Comments</h2>
                      <p>Allows for the comments on the various bids so you don't need to go through the approval
                          process.</p>
                  </div>
              </section>
              <div className="future-comments">
                  <p>I am continue to make improvements to help navigation and reviews easier</p>
              </div>
          </div>
          {/* Footer */}
          <footer className="footer">
              <div className="footer-content">
              <div><img src="/logo_header.png" alt="My Logo" className="footer-logo"/> </div>
                  <div className="footer-links">
                      <ul>
                          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                      </ul>
                  </div>
                  <p>&copy; {new Date().getFullYear()} Our Bridge Bids</p>
              </div>
          </footer>
      </div>
  );
}

export default LandingPage;