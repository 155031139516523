import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from './components/Login/LoginForm';
import axios from "axios";
import { API_BASE_URL } from './config/config';
import './styles/Header.css';
import getCsrfToken from './utils/getCsrfToken';

function Header({ onUserIdChange, onUserUpdate }) {
    const [user, setUser] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const handleUserInfo = useCallback((id, first_name, last_name, username) => {
        const userInfo = { id, first_name, last_name, username };
        setUser(userInfo);
        onUserIdChange(id); // Pass only the user ID to the App component
        onUserUpdate(userInfo); // Pass the full user info to the App component
    },[onUserIdChange, onUserUpdate]);

    const handleLogoClick = () => {
        navigate('/');
    };

    const checkUserLoggedIn = useCallback(async () => {
        try {
            const response = await axios({
                method: 'get',
                url: `${API_BASE_URL}/user/logcheck/`,
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            });
            if (response.status === 200) {
                const { id, first_name, last_name, username } = response.data.user;
                handleUserInfo(id, first_name, last_name, username);
            }
        } catch (error) {
            console.log('User is not logged in', error);
        }
    }, [handleUserInfo]);

    useEffect(() => {
        checkUserLoggedIn().then();
    }, [checkUserLoggedIn]);


    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowLogin(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);


    const handleLogout = async () => {
        try {
            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/user/logout/`,
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCsrfToken()
                }
            });
            if (response.status === 200) {
                setUser(null);
                onUserIdChange(null);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="header">

            <div className="header-left" onClick={handleLogoClick}>
                <img src="/logo_header.png" alt="Our Bridge Bids Logo" className="header-logo"/>
                <h1>Our Bridge Bids</h1>
            </div>
            <div className="header-right">
                {user ? (
                    <>
                        <p className="intro-text">Hello, {user.first_name} {user.last_name}</p>
                        <button onClick={handleLogout}>Logout</button>
                    </>
                ) : (
                    <button onClick={() => setShowLogin(true)}>Login/Signup</button>
                )}
                {showLogin && (
                    <div className="login-dropdown" ref={dropdownRef}>
                        <LoginForm userInfo={handleUserInfo} onClose={() => setShowLogin(false)}/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Header;