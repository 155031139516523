import React, { useState, useEffect } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import getCsrfToken from "../../utils/getCsrfToken";
import "../../styles/Notifications.css"


function NotificationBell() {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  const fetchNotifications = () => {
      axios({
          method: 'get',
          url: `${API_BASE_URL}/api/bid/notifications/0`,
          withCredentials: true,
      })
          .then(response => setNotifications(response.data));
  };


  useEffect(() => {
      fetchNotifications();
  }, []);

  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
  };

  const handleNotificationClick = (id) => {
      axios({
          method: 'put',
          url: `${API_BASE_URL}/api/bid/notifications/${id}`,
          withCredentials: true,
          headers: { 'X-CSRFToken': getCsrfToken() }
      })
          .then(fetchNotifications);
  };

    const handleDismissAll = () => {
        notifications.forEach(notification => {
            axios({
                method: 'delete',
                url: `${API_BASE_URL}/api/bid/notifications/${notification.id}`,
                withCredentials: true,
                headers: { 'X-CSRFToken': getCsrfToken() }
            })
                .then(fetchNotifications);
        });
    };

  const handleDeleteClick = (id) => {
      axios({
          method: 'delete',
          url: `${API_BASE_URL}/api/bid/notifications/${id}`,
          withCredentials: true,
          headers: { 'X-CSRFToken': getCsrfToken() }
      })
          .then(fetchNotifications);
  };

  return (
      <div className={`notifications-container ${showNotifications ? 'show' : ''}`}>
          <div className="bell-icon" onClick={handleBellClick}>
              {notifications.length > 0 && (
                  <div className="notification-count">{notifications.length}</div>
              )}
          </div>
          <div className="notifications-panel">
              <div className="notifications-panel-header">
                  {notifications.length > 0 && (
                      <button className="dismiss-all-button" onClick={handleDismissAll}>Dismiss All</button>
                  )}
                    <h3>Notifications</h3>
              </div>
              {notifications.map(notification => (
                  <div key={notification.id} className="notification">
                      <div className="notification-header">
                          <h4>{notification.agreement_name}<br />{notification.path}</h4>
                      </div>
                      <div
                          onClick={() => handleNotificationClick(notification.id)}
                          className={notification.read ? 'notification-text' : 'notification-text bold'}
                      >
                          {notification.message}
                      </div>
                      <button onClick={() => handleDeleteClick(notification.id)} className="delete-button">
                          🗑️ {/* Or use an icon from a library like Font Awesome */}
                      </button>
                  </div>
              ))}
          </div>
      </div>
  );
}

export default NotificationBell;